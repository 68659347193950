import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { Drug } from '@common/models/drugs/types';
import { Patient } from '@common/models/patients/types';
import { Physician } from '@common/models/physicians/types';
import { Prescription } from '@common/models/prescriptions/types';
import { PrescriptionFill } from '@common/models/prescriptionFills/types';

export type PrescriptionFillUpsertWithReferencesBody = {
  kioskId?: string;
  drug: Pick<Drug, 'name' | 'NDC' | 'quantity'>;
  patient: Pick<Patient, 'firstName' | 'lastName' | 'DOB' | 'email' | 'phoneNumber' | 'externalPharmacyId'>;
  physician: Pick<Physician, 'firstName' | 'lastName' | 'NPI' | 'SPI' | 'stateLicenseNumber' | 'externalPharmacyId'>;
  prescription: Pick<Prescription, 'rxId' | 'refills' | 'directions'>;
  prescriptionFill: Pick<PrescriptionFill, 'copay' | 'fillNumber'>;
};

export default flow(function* (body: PrescriptionFillUpsertWithReferencesBody) {
  const response: PrescriptionFill = yield http.put({
    url: `${API_BASE}${MODEL_ROUTE}/with-references`,
    body,
  });

  return response;
});
