import { flow } from 'mobx-state-tree';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

// Types
import { Drug } from '@common/models/drugs/types';
import { ComputedInventory } from '@common/models/kiosks/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export type GetComputedInventoryResponse = {
  drugs: Array<{
    id: Drug['id'];
    name: Drug['name'];
    NDC: Drug['NDC'];
    quantity: Drug['quantity'];
    strength: Drug['strength'];
    activeNumeratorStrength: Drug['activeNumeratorStrength'];
    kioskDrugThreshold: {
      highContainersCount: number;
      lowContainersCount: number;
    };
  }>;
  kiosk: {
    id: string;
    computed_inventory: ComputedInventory;
  };
  slotTemplates: Array<{
    id: string;
    description: string;
  }>;
};

/**
 *
 */
export default flow(function* (kioskId: string, apiKey: string) {
  const response: GetComputedInventoryResponse = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/computed-inventory`,
    query: omitBy({ kioskId, apiKey }, isNil),
  });

  return response;
});
