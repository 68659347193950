import { flow } from 'mobx-state-tree';

import { LotDecorated } from '@common/models/lots/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export default flow(function* (lotId: string) {
  const response: LotDecorated = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/${lotId}`,
  });

  return response;
});
