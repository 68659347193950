import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export default flow(function* (drugId: string) {
  const response: any = yield http.delete({
    url: `${API_BASE}${MODEL_ROUTE}/${drugId}`,
  });

  return response;
});
