import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Query = {
  NDC: string;
  quantity?: string;
};

type Response = {
  directions: string | null;
};

export default flow(function* (NDC: string, quantity?: string) {
  const query: Query = { NDC };
  if (quantity) {
    query.quantity = quantity;
  }

  const response: Response = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/directions`,
    query,
  });

  return response.directions;
});
