import { flow } from 'mobx-state-tree';

// Types
import { GooglePlace } from '@common/models/locations/types';

// Utils
import http from '@clientCommon/app/bootstrap/http';

// Constants
import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Query = {
  filter: {
    name: string;
  };
};

/**
 *
 */
export default flow(function* (filter: string) {
  const query = {
    title: filter,
  };

  const response: GooglePlace[] = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/autocomplete`,
    query,
  });

  return response;
});
