import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from '@clientCommon/library/components/contentScreen';
import InfiniteScroll from '@clientCommon/library/components/infiniteScroll';

import Header from './components/header';
import Content from './components/content';

import { SmartTableViewProps } from './types';

import './smartTableStyles.scss';

const SmartTableView = ({
  columns,
  rows,
  onRowClick,
  sortingFieldName,
  sortingOrder,
  onSortIconClick,
  getRowId,
  activeRow,
  activeRows,
  onRowSelect,
  onRowDeselect,
  selectUnavailableCaption,
  loadMoreData,
  loading,
  columnsInvisible,
  customClassName,
}: SmartTableViewProps) => (
  <div className={`SmartTable ${customClassName || ''}`}>
    <div className="smart-table-scroll-x">
      {!columnsInvisible && (
        <Header
          sortingFieldName={sortingFieldName}
          sortingOrder={sortingOrder}
          onSortIconClick={onSortIconClick}
          columns={columns}
        />
      )}
      <div className="smart-table-scroll-y">
        <InfiniteScroll onBottomThreshold={() => loadMoreData && loadMoreData()}>
          <div className="smart-table-content">
            <ContentScreen active={loading} />
            {rows.map((row) => {
              const rowId = getRowId(row);
              return (
                <Content
                  key={rowId}
                  active={rowId === activeRow || Boolean(activeRows && activeRows[rowId])}
                  columns={columns}
                  content={row}
                  onRowClick={onRowClick}
                  onRowSelect={onRowSelect}
                  onRowDeselect={onRowDeselect}
                  selectUnavailableCaption={selectUnavailableCaption}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  </div>
);

export default observer(SmartTableView);
