import { flow } from 'mobx-state-tree';

// Types
import { Drug } from '@common/models/drugs/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

/**
 *
 */
export default flow(function* (kioskId: string) {
  const response: { data: Drug[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/for-pharmacist/kiosks/${kioskId}`,
  });

  return response.data;
});
