import store from '@clientCommon/app/bootstrap/store';
import persistentStorage from '@clientCommon/app/bootstrap/persistentStorage';

const logOut = () => {
  persistentStorage.remove('accessToken');
  store.global.auth.setValue('accessToken', null);
  window.location.reload();
};

export default logOut;
