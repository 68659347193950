import { flow } from 'mobx-state-tree';

// Types
import { KioskDecorated } from '@common/models/kiosks/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

/**
 *
 */
export default flow(function* () {
  const response: KioskDecorated = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/current`,
  });

  return response;
});
