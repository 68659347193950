import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Response = Array<{
  productName: string;
  downloadUrl: string;
}>;

export default flow(function* () {
  const response: Response = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/kiosk/servers`,
  });

  return response;
});
