import { flow } from 'mobx-state-tree';

// Types
import { HealthcareSite } from '@common/models/healthcareSites/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

/**
 *
 */
export default flow(function* (healthcareSiteId: string) {
  const response: HealthcareSite = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/${healthcareSiteId}`,
  });

  return response;
});
