import { observer } from 'mobx-react';
import React from 'react';

// Types
import { SearchInputContainerProps } from './types';

// Views
import SearchInputView from './searchInputView';

function SearchInputContainer({ placeholder, value, className, onChange, onSearch, debounce }: SearchInputContainerProps) {
  return (
    <SearchInputView
      placeholder={placeholder}
      value={value}
      className={className}
      onChange={onChange}
      onSearch={onSearch}
      debounce={debounce}
    />
  );
}

export default observer(SearchInputContainer);
