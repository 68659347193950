import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { Container } from '@common/models/containers/types';

export default flow(function* (containerId: string) {
  const response: Container = yield http.put({
    url: `${API_BASE}${MODEL_ROUTE}/${containerId}/invalidate`,
    body: {},
  });

  return response;
});
