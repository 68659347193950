import { flow } from 'mobx-state-tree';

import { PrescriptionFillDecorated } from '@common/models/prescriptionFills/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Query = {
  filter?: string;
};

export default flow(function* (lotId: string, filterValue?: string) {
  const query = {} as Query;
  if (filterValue) {
    query.filter = filterValue;
  }

  const response: { data: PrescriptionFillDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/lots/${lotId}`,
    query,
  });

  return response.data;
});
