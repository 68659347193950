// External
import { types } from 'mobx-state-tree';

// Locals
import global from './global';
import models from './models';

const store = types.optional(
  types.model({
    global,
    /** The application store.models. */
    models: types.optional(types.model('ApplicationModels', models), {} as any),
  }),
  {}
);

const created = store.create({});

console.log('@clientCommon/app/bootstrapped store', created);

export default created;

export type StoreType = typeof created;
export type ModelsType = typeof created['models'];
