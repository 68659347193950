import { flow } from 'mobx-state-tree';

// Types
import { PrescriptionFillDecorated } from '@common/models/prescriptionFills/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export default flow(function* (filter?: string, offset?: number) {
  const response: { data: PrescriptionFillDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
    query: { filter, offset },
  });

  return response.data;
});
