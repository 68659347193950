import EventEmitter from 'events';
import { flow, IAnyType, IOptionalIType, ISimpleType, types } from 'mobx-state-tree';

type Props = { [key in string]: IAnyType };

type Computed = { [key in string]: () => any };

type Actions = { [key in string]: (...args: any[]) => any };

type Config<TProps, TComputed, TActions, TAsyncActions> = {
  props?: TProps;
  computed?: TComputed;
  actions?: TActions;
  asyncActions?: TAsyncActions;
};

const constructModel = <
  TProps extends Props,
  TComputed extends Computed,
  TActions extends Actions,
  TAsyncActions extends Actions
>({
  props = {} as TProps,
  computed = {} as TComputed,
  actions = {} as TActions,
  asyncActions = {} as TAsyncActions,
}: Config<TProps, TComputed, TActions, TAsyncActions>) => {
  const emitter = new EventEmitter();

  const formattedProps = props;

  const formattedComputed = Object.keys(computed).reduce((agg, key) => {
    const computedGetter = computed[key];
    Object.defineProperty(agg, key, {
      get: () => computedGetter(),
      enumerable: true,
      configurable: true,
    });
    return agg;
  }, {} as { readonly [key in keyof TComputed]: ReturnType<TComputed[key]> });

  const asyncActionKeys = Object.keys(asyncActions);

  const asyncActionProps = {
    busy: types.optional(
      types.model({
        ...asyncActionKeys.reduce(
          (
            agg: { [KActionName in keyof TAsyncActions]: IOptionalIType<ISimpleType<number>, [undefined]> },
            key: keyof TAsyncActions
          ) => {
            agg[key] = types.optional(types.number, 0);
            return agg;
          },
          {} as any
        ),
      }),
      {} as any
    ),
  };

  const type = types.optional(
    types
      .model({
        ...formattedProps,
      })
      .actions((self) => {
        type SelfType = typeof self;
        type SelfKeys = keyof SelfType;
        return {
          setTyped: <TKey extends SelfKeys>(key: TKey, value: SelfType[TKey]) => {
            self[key] = value;
          },
        };
      })
      .props({
        computed: types.optional(
          types.model().views((self) => formattedComputed),
          {}
        ),
        async: types.optional(
          types
            .model({
              ...asyncActionProps,
            })
            .actions((self) => {
              const formattedActions: { [KActionName in keyof TAsyncActions]: TAsyncActions[KActionName] } =
                asyncActionKeys.reduce((agg, key: keyof TAsyncActions) => {
                  const action = asyncActions[key];
                  agg[key] = flow(function* (...args: any[]) {
                    (self.busy[key] as number) += 1;
                    try {
                      const result = yield Promise.resolve(action(...args));
                      (self.busy[key] as number) -= 1;
                      return result;
                    } catch (err) {
                      (self.busy[key] as number) -= 1;
                      throw err;
                    }
                  });

                  return agg;
                }, {} as any);

              return formattedActions;
            }),
          {} as any
        ),
      })
      .actions((self) => ({
        ...actions,
      }))
      .actions((self) => ({
        on: (eventName: string, callback: (...args: any[]) => void) => emitter.on(eventName, callback),
        off: (eventName: string, callback: (...args: any[]) => void) => emitter.off(eventName, callback),
        emit: (eventName: string, ...args: any[]) => emitter.emit(eventName, ...args),
      })),
    {} as any
  );

  // const type = types.optional(types.model({
  //   ...formattedProps,
  //   computed: types.optional(types.model().views(self => formattedComputed), {}),
  //   async: types.optional(types.model({
  //     ...asyncActionProps,
  //   })
  //   .actions(self => {
  //     const formattedActions: { [KActionName in keyof TAsyncActions]: TAsyncActions[KActionName] } = asyncActionKeys.reduce((agg, key: keyof TAsyncActions) => {
  //       const action = asyncActions[key];
  //       agg[key] = flow(function* (...args: any[]) {
  //         try {
  //           const result = yield Promise.resolve(action(...args));
  //           return result;
  //         } catch (err) {
  //           throw err;
  //         }
  //       });

  //       return agg;
  //     }, {} as any);

  //     return formattedActions;
  //   }), {} as any),
  // })
  // .actions(self => ({
  //   ...actions,
  // }))
  // .actions(self => ({
  //   on: (eventName: string, callback: (...args: any[]) => void) => emitter.on(eventName, callback),
  //   off: (eventName: string, callback: (...args: any[]) => void) => emitter.off(eventName, callback),
  //   emit: (eventName: string, ...args: any[]) => emitter.emit(eventName, ...args),
  // })), {} as any);

  return type;
};

export default constructModel;
