import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { Location } from '@common/models/locations/types';

type Body = {
  name: string;
  kioskId: string;
  pharmacyId: string;
  locationId: string;
};

const upsertLocation = flow(function* (placeId: string) {
  const response: Location = yield http.put({
    url: `${API_BASE}${MODEL_ROUTE}`,
    body: {
      placeId,
    },
  });

  return response;
});

export default upsertLocation;
