import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Filter = {
  name?: string;
  NDC?: string;
  UPC?: string;
};

type Query = {
  limit?: number;
  offset?: number;
  order?: {
    field: string;
    direction: 'asc' | 'desc';
  };
  filter?: Filter;
};

export default flow(function* (filter: Filter = {}, queryOptions?: Exclude<Query, 'filter'>) {
  const query: Query = {
    ...queryOptions,
    filter,
  };

  const response: number = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/count`,
    query,
  });

  return response;
});
