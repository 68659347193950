import { flow } from 'mobx-state-tree';

import { DrugDecorated } from '@common/models/drugs/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export default flow(function* (lotId: string) {
  const response: { data: DrugDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/lots/${lotId}`,
  });

  return response.data;
});
