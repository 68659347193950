import { types } from 'mobx-state-tree';

import auth from './auth';

const type = types.optional(
  types.model({
    auth: types.optional(auth, {})
  }),
  {}
);

export default type;

export const node = type.create({
});
