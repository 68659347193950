import { flow } from 'mobx-state-tree';

import { StockingBatchDecorated } from '@common/models/stockingBatches/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export default flow(function* (stockingBatchId: string) {
  const response: StockingBatchDecorated = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/${stockingBatchId}`,
  });

  return response;
});
