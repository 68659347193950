import { flow } from 'mobx-state-tree';

// Types
import { Patient } from '@common/models/patients/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Filter = {
  firstName: string;
  lastName: string;
  DOB: string;
  zip: string;
};

export default flow(function* (filter: Filter, identifyingAccessToken: string) {
  const response: Patient | null = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/captured`,
    headers: {
      Identifier: identifyingAccessToken,
    },
    query: filter,
  });

  return response;
});
