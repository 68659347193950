import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Response = {
  transactionSetupId: string;
};

const prepareTransactionForTesting = flow(function* () {
  const response: Response = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/prepare-transaction/for-testing`,
    body: {},
  });

  return response.transactionSetupId;
});

export default prepareTransactionForTesting;
