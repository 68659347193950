import { flow } from 'mobx-state-tree';

// Types
import { PharmacyDecorated } from '@common/models/pharmacies/types';

// Utils
import http from '@clientCommon/app/bootstrap/http';

// Constants
import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

// Stores
import store from '@clientCommon/app/bootstrap/store';

type Filter = {
  name?: string;
};

export default flow(function* (filter: Filter = {}, { save }: { save?: boolean } = {}) {
  const response: { data: PharmacyDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
    query: { filter },
  });

  const pharmacies = response.data;

  if (save !== false) store.models.pharmacies.mergePharmacies(pharmacies);

  return pharmacies;
});
