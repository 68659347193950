import Http from '@clientCommon/library/utils/http';
import { API_BASE } from '@clientCommon/app/constants/index';

import store from '@clientCommon/app/bootstrap/store';
import { HttpPayload } from '@clientCommon/library/utils/http/types';

const http = new Http();

export default http;

// // sets token to requests if token exists and matches api base
http.addPreRequest((_payload) => {
  const { accessToken: token } = store.global.auth;

  const payload: Partial<HttpPayload> = _payload || {};
  if (payload.url && payload.url.match(API_BASE) && !!token) {
    payload.headers = {
      Authorization: `Bearer ${token}`,
      ...(payload.headers || {}),
    };
    payload.mode = 'cors';
  }
  return payload as HttpPayload;
});

http.addPostResponseError((err) => {
  console.log('HTTP Post Response Error', err.message, 'Namo', err.name, 'Texto', err.text, err.status);
  if (
    err.text.match('Unauthorized') ||
    err.text.match('The access token provided is invalid') ||
    (err.text.match('No authenticated user found associated with this request') && store.global.auth.accessToken)
  ) {
    store.models.users.logOut();
    console.log('Auth error', err);
  }
  return Promise.reject(err);
});
