import { flow } from 'mobx-state-tree';

import { PrescriptionFillDispenseFlow } from '@common/models/prescriptionFills/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Query = {
  order?: string[];
  kioskId?: string;
  healthcareSiteId?: string;
};

export default flow(function* (healthcareSiteId?: string | null, kioskId?: string | null) {
  const query: Query = {
    order: ['createdAt', 'asc'],
  };
  if (kioskId) {
    query.kioskId = kioskId;
  }
  if (healthcareSiteId) {
    query.healthcareSiteId = healthcareSiteId;
  }

  const response: { data: PrescriptionFillDispenseFlow[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/for-kiosk`,
    query,
  });

  return response.data;
});
