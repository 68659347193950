import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { User } from '@clientCommon/@common/models/users/types';

type Props = {
  authMultifactorToken: string;
  code: string;
};

type Response = {
  token: {
    access: string;
  };
  user: User;
};

const verifyLoginCode = flow(function* ({ authMultifactorToken, code }: Props) {
  const response: Response = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/login/verify-code`,
    headers: {
      AuthMultifactorCodeToken: authMultifactorToken,
    },
    body: {
      code,
    },
  });

  return response;
});

export default verifyLoginCode;
