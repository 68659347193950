import React from 'react';
import { observer } from 'mobx-react';
import { transform as _transform } from 'lodash';

// Components
import ContentGate from '@clientCommon/library/components/contentGate/index.web.p';
import Header from './components/header';
import Content from './components/content';

// Types
import { DrillDownLayoutContentViewProps } from './types';

import './drillDownLayoutContentStyles.scss';


const DrillDownLayoutContentView = ({ activeTab, setActiveTabName, isReady, tabs }: DrillDownLayoutContentViewProps) => {
  return <div className='c-Content'>
    {
      activeTab && activeTab.header &&
      <Header
        style={activeTab.header.style}
        title={activeTab.header.title}
        subtitle={activeTab.header.subtitle}
        subHeader={activeTab.header.subHeader}
        leftLabel={activeTab.header.leftLabel}
      />
    }
    <ContentGate className='drilldown-content-gate' active={!isReady} fadeDuration={500}>
      <div className='content-body'>
        <div className='tabs-container'>
          <div className='tabs-selectors-container'>
            {
              tabs.map(({ content }) => (
                <button
                  key={content.title}
                  className={`tab-selector ${content.title === activeTab?.content.title ? 'active' : 'non-active'}`}
                  onClick={() => setActiveTabName(content.title)}
                >
                  {
                    content.title
                  }
                </button>
              ))
            }
          </div>
          {
            activeTab &&
            <div className='tab-content-container'>
              <Content>
                {
                  activeTab.content.component
                }
              </Content>
            </div>
          }
        </div>
      </div>
    </ContentGate>
  </div>;
}

export default observer(DrillDownLayoutContentView);
