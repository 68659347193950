import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { LotDrugRecall } from '@common/models/lotDrugRecalls/types';

const createLotDrugRecall = flow(function* (drugId: string, lotId: string) {
  const response: LotDrugRecall = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}`,
    body: {
      drugId,
      lotId,
    },
  });

  return response;
});

export default createLotDrugRecall;
