import keyBy from 'lodash/keyBy';

// Types
import { Kiosk } from '@clientCommon/@common/models/kiosks/types';

// Store
import store from '@clientCommon/app/bootstrap/store';

export default (kiosks: Kiosk[]) => {
  const keyed = keyBy(kiosks, 'id');
  store.models.kiosks.byId.replace(keyed);
};
