import { SortingOrder } from '../../../types';

const setSortIconAction = (columnFieldName: string, sortingFieldName: string, sortingOrder: SortingOrder, onClick: (columnFieldName: string, sortingOrder: SortingOrder) => void) => {
  if (columnFieldName === sortingFieldName) {
    if (sortingOrder === 'desc') {
      return onClick(columnFieldName, 'asc');
    } else {
      return onClick(columnFieldName, 'desc');
    }
  } else {
    return onClick(columnFieldName, 'asc');
  }
};

export default setSortIconAction;
