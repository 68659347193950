import React from 'react';
import { observer } from 'mobx-react';

// Components
import InventoryTable from './components/inventoryTable';
import ContentScreen from '@clientCommon/library/components/contentScreen';
import Search from '@clientCommon/library/components/searchInput';

// Types
import { KioskInventoryViewProps } from './types';

// Styles
import './kioskInventoryStyles.scss';

const KioskInventoryView = ({ domain, fetchInventory }: KioskInventoryViewProps) => (
  <div className="c-KioskInventory">
    <ContentScreen active={domain.isBusy} />
    <Search
      placeholder="Drug or NDC"
      value={domain.filter}
      onChange={(term) => domain.setValue('filter', term)}
      // We only need a local filter for inventory, this just refreshes inventory after 3 seconds
      // for good measure, but ignores filter. Filter is completely handled on client
      onSearch={() => {
        fetchInventory();
      }}
      debounce={{ wait: 3000 }}
    />
    <InventoryTable
      sortingFieldName={domain.sortingColumn.fieldName}
      sortingOrder={domain.sortingColumn.sortingOrder}
      rows={domain.forTable}
      sortInventory={async (fieldName, sortingOrder) => {
        await fetchInventory();
        domain.setSortingColumn(fieldName, sortingOrder);
      }}
    />
  </div>
);

export default observer(KioskInventoryView);
