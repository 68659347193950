import React from 'react';
import { observer } from 'mobx-react';

// Components
import SmartTable from '@clientCommon/library/components/smartTable';
import ContentScreen from '@clientCommon/library/components/contentScreen';

// Types
import { KioskStorageViewProps } from './types';

// Styles
import './kioskStorageStyles.scss';

const KioskStorageView = ({ domain }: KioskStorageViewProps) => (
  <div className="c-KioskStorage">
    <ContentScreen active={domain.isBusy} />
    <SmartTable
      customClassName="c-KioskSlotsTable"
      rows={domain.forTable}
      columns={[
        {
          name: 'Container Size',
          fieldName: 'containerSize',
          isSortable: false,
          width: '40%',
          render: ({ content }) => (
            <div key={content.containerSize} title={content.containerSize}>
              {content.containerSize}
            </div>
          ),
        },
        {
          name: 'Vacant Slots',
          fieldName: 'vacantSlotsCount',
          isSortable: false,
          width: '20%',
          render: ({ content }) => (
            <div key={content.vacantSlotsCount} className="vacant" title={content.vacantSlotsCount}>
              {content.vacantSlotsCount}
            </div>
          ),
        },
        {
          name: 'Purgeable Slots',
          fieldName: 'purgeableSlotsCount',
          isSortable: false,
          width: '20%',
          render: ({ content }) => (
            <div key={content.purgeableSlotsCount} className="purgeable" title={content.purgeableSlotsCount}>
              {content.purgeableSlotsCount}
            </div>
          ),
        },
        {
          name: 'Total Slots',
          fieldName: 'totalSlotsCount',
          isSortable: false,
          width: '20%',
          render: ({ content }) => (
            <div key={content.totalSlotsCount} className="total" title={content.totalSlotsCount}>
              {content.totalSlotsCount}
            </div>
          ),
        },
      ]}
    />
  </div>
);

export default observer(KioskStorageView);
