import { types } from 'mobx-state-tree';

import persistentStorage from '@clientCommon/app/bootstrap/persistentStorage';

import store from '@clientCommon/app/bootstrap/store';

export default types.optional(
  types
    .model({
      hasCheckedLocalStorage: types.optional(types.boolean, false),
      accessToken: types.maybeNull(types.string), // TODO remove this
      accessTokens: types.optional(types.map(types.string), {}),
    })
    .actions((self) => ({
      setValue: <TKey extends keyof typeof self>(key: TKey, value: typeof self[TKey]) => (self[key] = value),
      setAccessToken: (tokenName: string, accessToken: string | null) => {
        if (accessToken) {
          self.accessTokens.set(tokenName, accessToken);
          persistentStorage.set(tokenName, accessToken);
        }
        else {
          self.accessTokens.delete(tokenName);
          persistentStorage.remove(tokenName);
        }
      },
    }))
    .actions((self) => ({
      hydrateToken: (tokenName: string) => {
        const token = persistentStorage.get(tokenName);
        if (token) self.accessTokens.set('accessToken', token);
        // const userData = await store.models.users.async.getCurrent();
        self.setValue('hasCheckedLocalStorage', true);
      },
      setTokenInLocalStorage: (accessToken: string, tokenName: string = 'accessToken') => {
        if (!accessToken) return;
        self.setValue('accessToken', accessToken);
        persistentStorage.set(tokenName, accessToken);
      },
      getTokenFromLocalStorage: async (tokenName: string = 'accessToken') => {
        self.setValue('accessToken', persistentStorage.get(tokenName));
        // const userData = await store.models.users.async.getCurrent();
        self.setValue('hasCheckedLocalStorage', true);
      },
    })),
  {}
);
