function getReactAppMedifriend() {
  if (typeof process !== 'undefined') {
    return process.env.REACT_APP_MEDIFRIEND;
    // @ts-ignore
  } else if (typeof import.meta.env !== 'undefined') {
    // @ts-ignore
    return import.meta.env.MODE;
  }

  return undefined;
}

type AppEnv = 'production' | 'staging' | 'development';
const API_BASE_BY_ENV: { [env in AppEnv]: string } = Object.freeze({
  production: 'https://api.medifriendrx.com',
  staging: 'https://api.staging.medifriendrx.com',
  development: 'http://localhost:8080',
});

const REACT_APP_MEDIFRIEND = getReactAppMedifriend() || 'staging';
export const API_BASE = API_BASE_BY_ENV[REACT_APP_MEDIFRIEND as AppEnv];

const UUID_CHAR = '0-9A-Fa-f';

export const RE = Object.freeze({
  UUID: `[${UUID_CHAR}]{8}\-[${UUID_CHAR}]{4}\-[${UUID_CHAR}]{4}\-[${UUID_CHAR}]{4}\-[${UUID_CHAR}]{12}`,
});

export const ELECTRON_API_DATA_CHANNEL = 'appChannel';
