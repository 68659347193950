/**
 * @module models/users/props
 * @see module:models/users
 */

import { types } from 'mobx-state-tree';

// Types
import { User } from '@clientCommon/@common/models/users/types';

export const activeUser = types.maybeNull(types.frozen<User>());
