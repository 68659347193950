import { useEffect, useState } from 'react';

import common from '@clientCommon/app/bootstrap/store';

import RootView from './rootView';

function RootContainer() {
  const [activeKioskId, setActiveKioskId] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const kioskId = query.get('kioskId');
    if (kioskId) {
      setActiveKioskId(kioskId);
    }
    const apiKey = query.get('apiKey');
    if (apiKey) {
      setApiKey(apiKey);
    }
  }, []);

  return (
    <RootView isBusy={Boolean(common.models.kiosks.async.busy.getComputedInventory)} activeKioskId={activeKioskId} apiKey={apiKey} />
  );
}

export default RootContainer;
