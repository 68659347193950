import { OFFLINE_TIMEOUT } from '../../constants';

const getIsKioskOnline = (latestStatusDate?: Date | null) => {
  if (!latestStatusDate) {
    return false;
  }

  const considerOfflineDate = new Date(Date.now() - OFFLINE_TIMEOUT);
  return latestStatusDate > considerOfflineDate;
};

export default getIsKioskOnline;
