import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { Patient } from '@common/models/patients/types';

const updatePatient = flow(function* (patient: Patient) {
  const response: Patient = yield http.put({
    url: `${API_BASE}${MODEL_ROUTE}/${patient.id}`,
    body: patient,
  });

  return response;
});

export default updatePatient;
