import React from 'react';
import { observer } from 'mobx-react';

// Components
import Content from '@clientCommon/library/components/drillDownLayout/components/content';
import Containers from '@clientCommon/app/views/kiosks/containers';
import Inventory from './children/inventory';
import Storage from './children/storage';

// Styles
import './inventoryStyles.scss';

type Props = {
  activeKioskId: string;
  apiKey: string | null;
};

function InventoryView({ activeKioskId, apiKey }: Props) {
  if (!activeKioskId || !apiKey) return <div>Must specify kioskId and apiKey</div>;
  return (
    <div className="c-InventoryView">
      <Content
        isReady={true}
        tabs={[
          {
            content: {
              title: 'Inventory',
              component: <Inventory kioskId={activeKioskId} apiKey={apiKey} />,
            },
          },
          {
            content: {
              title: 'Storage',
              component: <Storage kioskId={activeKioskId} apiKey={apiKey} />,
            },
          },
          {
            content: {
              title: 'Containers',
              component: <Containers kioskId={activeKioskId} apiKey={apiKey} />,
            },
          },
        ]}
      />
    </div>
  );
}

export default observer(InventoryView);
