import { types } from 'mobx-state-tree';

import store from '@clientCommon/app/bootstrap/store';

// Constants
import { SLOT_TYPE_READABLE, SLOT_TYPE_LIST } from '@common/models/moduleSlotTemplates/constants';

import { GetComputedInventoryResponse } from '@clientCommon/app/models/kiosks/asyncActions/getComputedInventory';

const kioskStorageRouteStore = () =>
  types.optional(
    types
      .model({
        inventory: types.frozen<GetComputedInventoryResponse | null>(),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends (typeof self)[K]>(key: K, value: V) => (self[key] = value),
        reset: () => {
          self.inventory = null;
        },
      }))
      .views((self) => ({
        get isBusy() {
          return Boolean(store.models.kiosks.async.busy.getComputedInventory);
        },
        get computedInventory() {
          if (!self.inventory) {
            return null;
          }

          return self.inventory.kiosk.computed_inventory;
        },
      }))
      .views((self) => ({
        get forTable() {
          const { computedInventory } = self;
          if (!computedInventory) {
            return [];
          }

          return SLOT_TYPE_LIST
          .filter((slotType) => Boolean(computedInventory.bySlotType[slotType]?.count?.total))
          .map((slotType) => {
            const { count } = computedInventory.bySlotType[slotType];
            return {
              containerSize: SLOT_TYPE_READABLE[slotType],
              purgeableSlotsCount: count.purgeable,
              totalSlotsCount: count.total,
              vacantSlotsCount: count.available,
            };
          });
        },
      })),
    {} as any
  );

export default kioskStorageRouteStore;
