import { flow } from 'mobx-state-tree';

import { Kiosk } from '@clientCommon/@common/models/kiosks/types';
import { StockingBatch } from '@common/models/stockingBatches/types';
import { User } from '@clientCommon/@common/models/users/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export type StockingBatchListResponse = StockingBatch & {
  kiosk: Kiosk;
  stockingUser: User;
};

export default flow(function* () {
  const response: { data: StockingBatchListResponse[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
  });

  return response.data;
});
