import React from 'react';
import { observer } from 'mobx-react';

// Components
import ContentGate from '@clientCommon/library/components/contentGate/index.web.p';

// Children
import NoKiosk from './children/noKiosk';
import Inventory from './children/inventory';

// Styles
import './rootViewStyles.scss';

type Props = {
  isBusy: boolean;
  activeKioskId: string | null;
  apiKey: string | null;
};

function RootView({ isBusy, activeKioskId, apiKey }: Props) {
  return (
    <ContentGate className="c-RootView" active={isBusy} fadeDuration={2000}>
      {!activeKioskId ? (
        <NoKiosk />
      ) : (
        <div className="content-container">
          <Inventory activeKioskId={activeKioskId} apiKey={apiKey} />
        </div>
      )}
    </ContentGate>
  );
}

export default observer(RootView);
