import { flow } from 'mobx-state-tree';

// Types
import { Container } from '@common/models/containers/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

/**
 *
 */
export default flow(function* (
  kioskId: string,
  query: {
    filter: {
      labelNDC?: string;
      quantity?: string;
      '$drug.name$': string;
      '$drug.NDC$': string;
      '$kioskModuleSlot.reference_kioskModuleName$': string;
      '$kioskModuleSlot.reference_slotName$': string;
    };
  },
  apiKey?: string | null,
) {
  // @ts-ignore
  if (apiKey) query.apiKey = apiKey;
  const response: { data: Container[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}/kiosks/${kioskId}/active`,
    query,
  });

  return response.data;
});
