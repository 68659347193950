import { flow, types } from 'mobx-state-tree';
import keyBy from 'lodash/keyBy';

// Types
import { ContainerActive } from '@common/models/containers/types';
import { KioskContainersDomainProps } from '../types';
import { SlotType } from '@common/models/moduleSlotTemplates/types';
import { GetComputedInventoryResponse } from '@clientCommon/app/models/kiosks/asyncActions/getComputedInventory';

// Constants
import { SLOT_TYPE_READABLE } from '@common/models/moduleSlotTemplates/constants';

// Store
import store from '@clientCommon/app/bootstrap/store';

const kioskContainersRouteStore = ({ kioskId, apiKey }: KioskContainersDomainProps) =>
  types.optional(
    types
      .model({
        activeContainers: types.map(types.frozen<ContainerActive>()),
        inventory: types.frozen<GetComputedInventoryResponse | null>(),
        selectedContainers: types.optional(types.map(types.frozen<ContainerActive>()), {}),
        filter: types.maybeNull(types.string),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends typeof self[K]>(key: K, value: V) => (self[key] = value),
        setSelectedContainer: (containerId: string, container: ContainerActive) =>
          self.selectedContainers.set(containerId, container),
        deleteSelectedContainer: (containerId: string) => self.selectedContainers.delete(containerId),
        reset: () => {
          self.activeContainers = {} as any;
          self.inventory = null;
          self.selectedContainers = {} as any;
          self.filter = null;
        },
      }))
      .views((self) => ({
        get activeContainerList() {
          return Array.from(self.activeContainers.values());
        },
        get computedInventory() {
          if (!self.inventory) {
            return null;
          }

          return self.inventory.kiosk.computed_inventory;
        },
        get selectedContainerIds() {
          return Array.from(self.selectedContainers.keys());
        },
        get isBusy() {
          return (
            Boolean(store.models.containers.async.busy.listByKioskId) ||
            Boolean(store.models.containers.async.busy.invalidate) ||
            Boolean(store.models.kiosks.async.busy.getComputedInventory)
          );
        },
      }))
      .views((self) => ({
        get purgeableSlotIds() {
          const bySlotType = (self.computedInventory || {}).bySlotType || {};
          const slotTypes = Object.keys(bySlotType) as SlotType[];
      
          return slotTypes.reduce((agg, slotType) => {
            const purgeableSlots = bySlotType?.[slotType]?.slots?.purgeable || [];
            const ids = purgeableSlots.map(({ kioskModuleSlotId }) => kioskModuleSlotId);
            agg[slotType] = new Set(ids);
            return agg;
          }, {} as Record<SlotType, Set<string>>);
        },
      }))
      .views((self) => ({
        isContainerPurgeable: (containerId: string) => {
          const container = self.activeContainers.get(containerId);
          if (!container) return false;
          
          const slotType = container?.kioskModuleSlot?.reference_slotTemplateId as SlotType;
          if (!slotType || !self.purgeableSlotIds[slotType]) return false;
      
          return self.purgeableSlotIds[slotType].has(container.kioskModuleSlot?.id);
        },
        isContainerPurgeablea: (containerId: string) => {
          const container = self.activeContainers.get(containerId);
          if (!container || !container.kioskModuleSlot || !container.kioskModuleSlot.reference_slotTemplateId) {
            return false;
          }

          const purgeableSlots =
            self.computedInventory?.bySlotType[container.kioskModuleSlot.reference_slotTemplateId].slots.purgeable ||
            [];
          const containerPurgeableSlot = purgeableSlots.find(
            (slot) => slot.kioskModuleSlotId === container.kioskModuleSlot.id
          );
          return Boolean(containerPurgeableSlot);
        },
      }))
      .views((self) => ({
        get activeContainerListFormatted() {
          return self.activeContainerList.map((container) => {
            let expiresAt = container.computed_effectiveExpiresAt || 'N/A';
            if (expiresAt !== 'N/A') {
              const asDate = new Date(expiresAt);
              expiresAt = `${asDate.getMonth() + 1}/${asDate.getDate()}/${asDate.getFullYear()}`;
            }

            let slotType = container.kioskModuleSlot.reference_slotTemplateId || 'N/A';
            if (slotType !== 'N/A') {
              slotType = `${slotType} (${SLOT_TYPE_READABLE[slotType as SlotType]})`;
            }

            return {
              id: container.id,
              labelNDC: container.labelNDC || 'N/A',
              quantity: container.quantity || 'N/A',
              drugName: container?.drug?.name || 'N/A',
              drugNDC: container?.drug?.NDC || 'N/A',
              slot: container.kioskModuleSlot.reference_slotName || 'N/A',
              module: container.kioskModuleSlot.reference_kioskModuleName || 'N/A',
              slotType,
              labelled: container.labelled ? 'Yes' : 'No',
              expiresAt,
              isSelectable: !self.isContainerPurgeable(container.id),
            };
          });
        },
      }))
      .actions((self) => ({
        fetchContainers: flow(function* () {
          if (!kioskId) return;
          if (!apiKey) return;

          // kioskContainersRouteStore.incrementHttp('fetchingContainers');

          const query: any = {};
          if (self.filter) {
            query.filter = {
              labelNDC: self.filter,
              quantity: self.filter,
              '$drug.name$': self.filter,
              '$drug.NDC$': self.filter,
              '$kioskModuleSlot.reference_kioskModuleName$': self.filter,
              '$kioskModuleSlot.reference_slotTemplateId$': self.filter,
              '$kioskModuleSlot.reference_slotName$': self.filter,
            };
          }
          const response = yield store.models.containers.async.listByKioskId(kioskId, query, apiKey);

          self.activeContainers.clear();
          self.activeContainers.merge(keyBy(response, 'id'));

          return response;
        }),
        fetchKioskInventory: flow(function* () {
          if (!kioskId) {
            return;
          }

          const response = yield store.models.kiosks.async.getComputedInventory(kioskId, apiKey);
          self.inventory = response;

          return response;
        }),
      }))
      .actions((self) => ({
        invalidateSelectedContainers: flow(function* () {
          if (!self.selectedContainerIds.length) {
            return;
          }

          const invalidateContainerPromises = self.selectedContainerIds.map((containerId) => {
            return store.models.containers.async.invalidate(containerId);
          });
          const invalidatedContainers = yield Promise.all(invalidateContainerPromises);

          self.selectedContainers = {} as any;

          yield Promise.all([self.fetchContainers(), self.fetchKioskInventory()]);

          return invalidatedContainers;
        }),
      })),
    {} as any
  );

export default kioskContainersRouteStore;
