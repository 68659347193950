import { observer } from 'mobx-react';
import React, { useCallback, useRef } from 'react';

// Types
import { SearchInputViewProps } from './types';

// Hooks
import useDebounce from '@clientCommon/library/hooks/useDebounce';

// Assets
import SearchIcon from '@clientCommon/library/assets/icons/search1.svg';

// Styles
import './searchInputStyles.scss';

function SearchInputView({ placeholder, value, className, onChange, onSearch, debounce = {} }: SearchInputViewProps) {
  const latestChange = useRef<string>();

  const debounced = useDebounce(
    (term: string) => {
      if (!onSearch) return;
      onSearch(term);
    },
    {
      ...debounce,
      wait: debounce.wait || 250,
    },
    [onSearch]
  );

  const onChangeLocal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value === latestChange.current) return;
      latestChange.current = value;
      if (onChange) onChange(value);
      debounced(value);
    },
    [onSearch]
  );

  return (
    <div className="c-SearchInputView">
      <img className="search-icon" src={SearchIcon} />
      <input placeholder={placeholder} value={value || ''} onChange={onChangeLocal} />
    </div>
  );
}

export default observer(SearchInputView);
