import { isNumber as _isNumber } from 'lodash';

const formatTemperature = (temperature: number) => {
  if (!_isNumber(temperature)) {
    return temperature;
  }

  return `${temperature.toFixed(2)}°C`;
};

export default formatTemperature;
