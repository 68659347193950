import { flow } from 'mobx-state-tree';

import { HealthcareSiteDecorated } from '@common/models/healthcareSites/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

export default flow(function* () {
  const response: { data: HealthcareSiteDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
  });

  return response.data;
});
