import { SortingOrder } from '../../../types';

import SortAscIcon from '../../../assets/icons/sort-asc.svg';
import SortDescIcon from '../../../assets/icons/sort-desc.svg';
import SortNeautralIcon from '../../../assets/icons/sort-neutral.svg';

const getSortIcon = (columnFieldName: string, sortingFieldName: string, sortingOrder: SortingOrder) => {
  if (columnFieldName === sortingFieldName) {
    if (!sortingOrder || sortingOrder === 'desc') {
      return SortDescIcon;
    } else {
      return SortAscIcon;
    }
  } else {
    return SortNeautralIcon;
  }
};

export default getSortIcon;
