import keyBy from 'lodash/keyBy';

// Types
import { Pharmacy } from '@clientCommon/@common/models/pharmacies/types';

// Store
import store from '@clientCommon/app/bootstrap/store';

export default (pharmacies: Pharmacy[]) => {
  const keyed = keyBy(pharmacies, 'id');
  store.models.pharmacies.byId.merge(keyed);
};
