import React from 'react';

import H1 from '@clientCommon/library/components/composition/h1';

import './noKioskStyles.scss';

function NoKiosk() {
  return (
    <div className="c-NoKiosk">
      <H1 className="no-kiosk-text">Unable to Find or Load Kiosk</H1>
    </div>
  );
}

export default NoKiosk;
