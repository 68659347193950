import React from 'react';
import { observer } from 'mobx-react';
import { isBoolean as _isBoolean } from 'lodash';

import { DEFAULT } from '../../constants';

import getActiveClass from './helpers/getActiveClass';

import { Column, Row } from '../../types';

import CheckboxCheckedIcon from '../../assets/icons/checkbox-checked.svg';
import CheckboxUncheckedIcon from '../../assets/icons/checkbox-unchecked.svg';

import './styles.scss';

type Props = {
  active: boolean;
  columns: Column[];
  content: Row;
  onRowClick?: (row: Row) => void;
  onRowSelect?: (row: Row) => void;
  onRowDeselect?: (row: Row) => void;
  selectUnavailableCaption?: string;
};

const SmartTableContent = ({
  active,
  columns,
  content,
  onRowClick,
  onRowSelect,
  onRowDeselect,
  selectUnavailableCaption,
}: Props) => {
  const isRowSelectable = _isBoolean(content.isSelectable) ? content.isSelectable : true;

  return (
    <div className="SmartTableContent">
      <div
        className={`${Boolean(onRowClick) ? 'content-row--withClickable' : ''} ${getActiveClass(active)}`}
        onClick={() => {
          if (onRowClick) {
            onRowClick(content);
          }

          if (onRowSelect && onRowDeselect) {
            if (active) {
              onRowDeselect(content);
            } else {
              onRowSelect(content);
            }
          }
        }}
      >
        {columns.map((column) => (
          <div key={column.name} className="content-cell" style={{ width: column.width || DEFAULT.COLUMN_WIDTH }}>
            {column.render ? column.render({ column, content }) : <div>{content[column.name]}</div>}
          </div>
        ))}
        {isRowSelectable ? (
          onRowSelect && onRowDeselect ? (
            <div className="checkbox-container">
              <img
                className="checkbox"
                alt={active ? 'Deselect' : 'Select'}
                src={active ? CheckboxCheckedIcon : CheckboxUncheckedIcon}
              />
            </div>
          ) : null
        ) : selectUnavailableCaption ? (
          <div className="content-cell select-unavailable-caption">{selectUnavailableCaption}</div>
        ) : null}
      </div>
    </div>
  );
};

export default observer(SmartTableContent);
