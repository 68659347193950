import { useRef, useCallback, useEffect } from 'react';

export default function useDebounce<D extends any>(
  fn: (value: D) => void,
  options: {
    wait: number;
    maxWait?: number;
  },
  changeListeners: any[]
) {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const invokeBy = useRef<number | null>(null);

  const maxWait = options.maxWait || Infinity;

  // Establish cleanup in order to prevent debounce from firing after a component is exited.
  useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current);
    };
  }, []);

  // This is the function returned to the user of the hook in order to invoke the debounce.
  const debounced = useCallback(
    (value: D) => {
      timeoutId.current && clearTimeout(timeoutId.current);

      // If this isn't specified, this means this is a fresh invocation
      if (!invokeBy.current) invokeBy.current = Date.now() + maxWait;

      // If the current time is closer to the invokeBy time, disregard options.wait and instead go with this value.
      const remainingTimeout = Math.max(Math.min(options.wait, invokeBy.current - Date.now()), 0);

      // If remaining timeout is infinite, do not establish timeout at all. setTimeout(..., Infinity) actually invokes immediately
      // which is undesirable behavior.
      if (remainingTimeout === Infinity) return;

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        invokeBy.current = null;

        fn(value);
      }, remainingTimeout) as unknown as NodeJS.Timeout;
    },
    [fn, options.wait, maxWait, timeoutId.current, ...changeListeners]
  );

  return debounced;
}
