import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { transform as _transform } from 'lodash';

import DrillDownLayoutContentView from './drillDownLayoutContentView';

import { DrillDownLayoutContentContainerProps } from './types';

const DrillDownLayoutContentContainer = ({ isReady, tabs }: DrillDownLayoutContentContainerProps) => {
  const [activeTabName, setActiveTabName] = useState(null as string | null);

  const activeTab = useMemo(() => {
    return tabs.find(({ content }) => content.title === activeTabName);
  }, [activeTabName, tabs]);

  useEffect(() => {
    if (!activeTabName) {
      setActiveTabName(tabs[0].content.title);
    }
  }, [tabs]);

  return <DrillDownLayoutContentView
    activeTab={activeTab}
    setActiveTabName={setActiveTabName}
    isReady={isReady}
    tabs={tabs}
  />;
}

export default observer(DrillDownLayoutContentContainer);
