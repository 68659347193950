import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Params = {
  patientId: string;
  healthcareSiteId: string;
};

type Config = {
  identifyingAccessToken?: string;
};

type Response = {
  id: string;
  healthcareSiteId: string;
  patientId: string;
};

const associateHealthcareSite = flow(function* (
  { patientId, healthcareSiteId }: Params,
  { identifyingAccessToken }: Config = {}
) {
  const headers: { Identifier?: string } = {};
  if (identifyingAccessToken) {
    headers.Identifier = identifyingAccessToken;
  }

  const response: Response = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/${patientId}/healthcare-sites/${healthcareSiteId}`,
    body: {},
    headers,
  });

  return response;
});

export default associateHealthcareSite;
