import { types } from 'mobx-state-tree';

// Types
import { Pharmacy } from '@common/models/pharmacies/types';

// Store
import store from '@clientCommon/app/bootstrap/store';

export default () => {
  return Array.from(store.models.pharmacies.byId.values());
};
