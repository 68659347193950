import React from 'react';
import { observer } from 'mobx-react';

// Types
import { KioskContainersViewProps } from './types';

// Components
import ContentScreen from '@clientCommon/library/components/contentScreen';
import Search from '@clientCommon/library/components/searchInput';
import SmartTable from '@clientCommon/library/components/smartTable';

// Styles
import './kioskContainersStyles.scss';

const KioskContainersView = ({ domain }: KioskContainersViewProps) => {
  return (
    <div className="c-KioskContainers">
      <ContentScreen active={domain.isBusy && !domain.activeContainerListFormatted.length} />
      <div className="header">
        <div className="search-container">
          <Search
            placeholder="Drug or NDC"
            value={domain.filter || ''}
            onChange={(term) => domain.setValue('filter', term)}
            onSearch={() => domain.fetchContainers()}
          />
        </div>
        <div className="buttons-container">
          <button
            className={`button ${!domain.selectedContainers.size && 'button-disabled'}`}
            onClick={() => domain.invalidateSelectedContainers()}
          >
            Invalidate
          </button>
        </div>
      </div>
      <div className="content">
        <SmartTable
          customClassName="c-ContainersTable"
          loading={domain.isBusy}
          activeRows={Object.fromEntries(domain.selectedContainers)}
          rows={domain.activeContainerListFormatted}
          columns={[
            {
              name: 'Drug',
              fieldName: 'drugName',
              isSortable: false,
              width: '35%',
              render: ({ content }) => {
                return (
                  <div>
                    <div title={content.drugName} className="drug-name">
                      {content.drugName}
                      {content.activeNumeratorStrength}
                    </div>
                    <div>
                      <span className="NDC">NDC:</span>
                      {content.labelNDC}
                      <span className="quantity">QTY:</span>
                      {content.quantity || 'N/A'}
                    </div>
                  </div>
                );
              },
            },
            {
              name: 'Module',
              fieldName: 'module',
              isSortable: false,
              width: '10%',
              render: ({ content }) => (
                <div className="module" title={content.module}>
                  {content.module}
                </div>
              ),
            },
            {
              name: 'Slot',
              fieldName: 'slot',
              isSortable: false,
              width: '6%',
              render: ({ content }) => (
                <div className="slot" title={content.slot}>
                  {content.slot}
                </div>
              ),
            },
            {
              name: 'Slot Type',
              fieldName: 'slotType',
              isSortable: false,
              width: '10.5%',
              render: ({ content }) => (
                <div className="slotType" title={content.slotType}>
                  {content.slotType}
                </div>
              ),
            },
            {
              name: 'Labelled',
              fieldName: 'labelled',
              isSortable: false,
              width: '8%',
              render: ({ content }) => <div className="labelled">{content.labelled}</div>,
            },
            {
              name: 'Effective Expires At',
              fieldName: 'computed_effectiveExpiresAt',
              isSortable: false,
              width: '16%',
              render: ({ content }) => <div className="expiresAt">{content.expiresAt}</div>,
            },
          ]}
          onRowClick={() => {}}
          onRowSelect={(content) => {
            if (!domain.isContainerPurgeable(content.id)) {
              domain.setSelectedContainer(content.id, content);
            }
          }}
          onRowDeselect={(content) => domain.deleteSelectedContainer(content.id)}
          selectUnavailableCaption="Purgeable"
        />
      </div>
    </div>
  );
};

export default observer(KioskContainersView);
