import { flow } from 'mobx-state-tree';

// Types
import { KioskDecorated } from '@common/models/kiosks/types';

// Utils
import http from '@clientCommon/app/bootstrap/http';

// Constants
import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Query = {
  filter: {
    name: string;
  };
};

type Pagination = {
  limit: number;
  offset: number;
};

/**
 *
 */
export default flow(function* (filterValue?: string, pagination?: Pagination) {
  const query = {} as Query;
  if (filterValue) {
    query.filter = {
      name: filterValue,
    };
  }

  const response: { data: KioskDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
    query: {
      ...pagination,
      ...query,
    },
  });

  const kiosks = response.data;

  return kiosks;
});
