import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { User } from '@clientCommon/@common/models/users/types';

type Props = {
  email: string;
  password: string;
};

type BasicLoginResponse = {
  authMultifactorCodeToken: null;
  token: {
    access: string;
  };
  user: User;
};

type MultifactorAuthResponse = {
  authMultifactorCodeToken: string;
  token: null;
  user: null;
};

type Response = BasicLoginResponse | MultifactorAuthResponse;

/**
 *
 */
export default flow(function* ({ email, password }: Props) {
  const response: Response = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/login`,
    body: {
      email,
      password,
    },
  });

  return response;
});
