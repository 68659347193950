import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import SmartTableView from './smartTableView';

import { Row, SmartTableContainerProps } from './types';

const SmartTableContainer = ({
  columns,
  rows,
  onRowClick,
  sortingFieldName,
  sortingOrder,
  onSortIconClick,
  getRowId,
  activeRow,
  activeRows,
  onRowSelect,
  onRowDeselect,
  selectUnavailableCaption,
  loadMoreData,
  loading,
  columnsInvisible,
  customClassName,
}: SmartTableContainerProps) => {
  const getRowIdPrivate = useCallback(
    (row: Row) => {
      if (getRowId) {
        return getRowId(row);
      } else if (row.id) {
        return row.id;
      } else {
        return undefined;
      }
    },
    [getRowId]
  );

  return (
    <SmartTableView
      columns={columns}
      rows={rows}
      onRowClick={onRowClick}
      sortingFieldName={sortingFieldName}
      sortingOrder={sortingOrder}
      onSortIconClick={onSortIconClick}
      getRowId={getRowIdPrivate}
      activeRow={activeRow}
      activeRows={activeRows}
      onRowSelect={onRowSelect}
      onRowDeselect={onRowDeselect}
      selectUnavailableCaption={selectUnavailableCaption}
      loadMoreData={loadMoreData}
      loading={loading}
      columnsInvisible={columnsInvisible}
      customClassName={customClassName}
    />
  );
};

export default observer(SmartTableContainer);
