/**
 * @module models/legaleses
 */

 import * as _actions from './actions';
 import * as _asyncActions from './asyncActions';
 import * as _computed from './computed';
 import * as _props from './props';
 import * as _views from './views';
 
 export const actions = _actions;
 
 export const asyncActions = _asyncActions;
 
 export const computed = _computed;
 
 export const props = _props;
 
 export const views = _views;
 