import React from 'react';
import { observer } from 'mobx-react';

import getSortIcon from './helpers/getSortIcon';
import setSortIconAction from './helpers/setSortIconAction';

import { DEFAULT } from '../../constants';

import { Column, SortingOrder } from '../../types';

import './styles.scss';

type Props = {
  sortingFieldName?: string;
  sortingOrder?: 'asc' | 'desc';
  onSortIconClick?: (columnFieldName: string, sortingOrder: SortingOrder) => void;
  columns: Column[];
};

const SmartTableHeader = ({
  sortingFieldName,
  sortingOrder,
  onSortIconClick,
  columns,
}: Props) => (
  <div className="SmartTableHeader">
    {
      columns.map((column) => (
        <div
          className="header-cell"
          style={{ width: column.width || DEFAULT.COLUMN_WIDTH }}
          key={column.name}
        >
          <div className='title'>
            {
              column.display !== undefined
                ? column.display
                : column.name
            }
          </div>
          {
            column.fieldName && column.isSortable && sortingFieldName && sortingOrder && onSortIconClick &&
            <img
              style={{ cursor: 'pointer' }}
              className='icon'
              src={getSortIcon(column.fieldName, sortingFieldName, sortingOrder)}
              // @ts-ignore
              onClick={() => setSortIconAction(column.fieldName, sortingFieldName, sortingOrder, onSortIconClick)}
            />
          }
        </div>
      ))
    }
  </div>
);

export default observer(SmartTableHeader);
