const storage = {
  get: (key: string) => {
    return localStorage.getItem(key);
  },
  getJSON: (key: string) => {
    const result = storage.get(key);
    return result ? JSON.parse(result) : result;
  },
  set: (key: string, value: string) => {
    return localStorage.setItem(key, value);
  },
  setJSON: (key: string, value: any) => {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key: string) => {
    return localStorage.removeItem(key);
  },
};

export default storage;
