import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { Kiosk } from '@common/models/kiosks/types';

type Body = {
  name: string;
  kioskId: string;
  pharmacyId: string;
  locationId: string;
};

const cloneKiosk = flow(function* ({ name, kioskId, pharmacyId, locationId }: Body) {
  const response: Kiosk = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/${kioskId}/clone`,
    body: {
      name,
      pharmacyId,
      locationId,
    },
  });

  return response;
});

export default cloneKiosk;
